<template>
  <VaLayout v-if="breakpoint.lgUp" class="h-screen bg-[var(--va-background-secondary)]">
    <template #left>
      <RouterLink
        to="/"
        class="bg-primary h-full flex items-center justify-center"
        style="width: 35vw"
        aria-label="Visit homepage"
      >
        <div class="text-white text-2xl font-bold flex justify-between w-28">
          <span> J O O N </span>
          <span class="text-sm">AI</span>
        </div>
      </RouterLink>
    </template>
    <template #content>
      <main class="h-full flex items-center justify-center mx-auto max-w-[420px]">
        <RouterView />
      </main>
    </template>
  </VaLayout>

  <VaLayout v-else class="h-screen bg-[var(--va-background-secondary)]">
    <template #content>
      <div class="p-4">
        <main class="h-full flex flex-row items-center justify-start mx-auto max-w-[420px]">
          <div class="flex flex-col items-start">
            <RouterLink to="/" class="py-4" aria-label="Visit homepage">
              <div class="text-blue-700 text-2xl font-bold flex justify-between w-28">
                <span> J O O N </span>
                <span class="text-sm">AI</span>
              </div>
            </RouterLink>
            <RouterView />
          </div>
        </main>
      </div>
    </template>
  </VaLayout>
</template>

<script lang="ts" setup>
import { useBreakpoint } from 'vuestic-ui'

const breakpoint = useBreakpoint()
</script>
